import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const ProjectCard = makeShortcode("ProjectCard");
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h2>{`Skills`}</h2>
    <ProjectCard title="Python" link="https://www.python.org/" bg="linear-gradient(to right, #D4145A 0%, #FBB03B 100%)" mdxType="ProjectCard">
Three years of web development with Django
    </ProjectCard>
    <ProjectCard title="PHP" link="https://www.python.org/" bg="linear-gradient(to right, #D4145A 0%, #3b7ffb 100%)" mdxType="ProjectCard">
Three years of PHP web development with frameworks like : Zend, CakePHP, CodeIgniter, Symfony 
    </ProjectCard>
    <ProjectCard title="React" link="https://fr.reactjs.org/" bg="linear-gradient(to right, #1423d4 0%, #FBB03B 100%)" mdxType="ProjectCard">
Learning ReactJS to be a better FrontEnd developer
    </ProjectCard>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      