import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h2>{`About`}</h2>
    <p>{`Web developper for six years, I mainly focus on delivering quality code on various projects from passport personalisation to cash register software.`}</p>
    <p>{`My day to day tools are cli, Docker and Git.`}</p>
    <p>{`I'm also passionate about various subjects like reading, photography, climbing and surfing.`}</p>
    <blockquote>
      <p parentName="blockquote">{`You can contact me `}<a parentName="p" {...{
          "href": "mailto:home@damien.link"
        }}>{`here`}</a>{`.`}</p>
    </blockquote>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      